

.home-wrapper {
  display: flex;
}

.searchcard:hover {
  cursor: pointer;
  box-shadow: 0 0 11px #21212133;
}

.taglikehover:hover {
  color: #2c7a7b;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 991px) {
  .vertical-cards {
    place-self: center;
  }
}

/*# sourceMappingURL=index.988bc3a7.css.map */
